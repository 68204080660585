import { writable } from 'svelte/store';
import { get } from 'svelte/store';

const _default_host = window.location.hostname + (DEV_MODE ? `:${HTTPS ? PORT : SERVER_PORT}` : ``);
const _test_host = TEST_HOST;
console.log("_test_host", TEST_HOST, _test_host)
export default {
    _host: writable(_default_host),
    _graphql_uri: (host) => `http${HTTPS ? 's': ''}://${host}/graphql`,
    _files_uri: (host) => `http${HTTPS ? 's': ''}://${host}/files`,
    _pusher_uri: (host) => `http${HTTPS ? 's': ''}://${host}/pusher`, 
    _novu_uri: (host) => `${DEV_MODE ? NOVU_HOST : ""}` + NOVU_API_URL,
    _novu_ws_uri: (host) => `${DEV_MODE ? NOVU_HOST : ""}` + NOVU_WS_URL, // TODO: We asume that on production its the same host /novu for example
    config(storage) {
        if(storage?.HOST) this._host.set(storage.HOST);
        const { subscribe, set, update } = writable(this.init())

        return {
            subscribe,
            setHost: (val) => {
                val = val.replace(/http:\/\/|https:\/\//, "")
                this._host.set(val);
                return set(this.init())
            },
            get: (config = false) => {
                if(!config) return get({ subscribe })
                return get({ subscribe })[config] || get(this["_" + config.toLowerCase()])
            }
        };
    },   
    init() {
        return {
            HOST: get(this._host),
            GRAPHQL_URI: this._graphql_uri(get(this._host)),
            FILES_URI: this._files_uri(get(this._host)),
            PUSHER_URI: this._pusher_uri(get(this._host)),
            NOVU_URI: this._novu_uri(get(this._host)),
            NOVU_WS_URI: this._novu_ws_uri(get(this._host)),
            NOT_DEFAULT: _default_host !== get(this._host),
            TESTING_HOST: _test_host,
            DEFAULT_HOST: _default_host,
        };
    } 
}