

export default {
  "meta": {},
  "id": "_default",
  "name": "",
  "file": {
    "path": "src/routes/root/_module.svelte",
    "dir": "src/routes/root",
    "base": "_module.svelte",
    "ext": ".svelte",
    "name": "_module"
  },
  "asyncModule": () => import('../src/routes/root/_module.svelte'),
  "rootName": "default",
  "routifyDir": import.meta.url,
  "children": [
    {
      "meta": {
        "dynamic": true,
        "order": false
      },
      "id": "_default___username__svelte",
      "name": "@[username]",
      "file": {
        "path": "src/routes/root/@[username].svelte",
        "dir": "src/routes/root",
        "base": "@[username].svelte",
        "ext": ".svelte",
        "name": "@[username]"
      },
      "asyncModule": () => import('../src/routes/root/@[username].svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_FeedbackModal_svelte",
      "name": "FeedbackModal",
      "file": {
        "path": "src/routes/root/FeedbackModal.svelte",
        "dir": "src/routes/root",
        "base": "FeedbackModal.svelte",
        "ext": ".svelte",
        "name": "FeedbackModal"
      },
      "asyncModule": () => import('../src/routes/root/FeedbackModal.svelte'),
      "children": []
    },
    {
      "meta": {
        "dynamic": true,
        "order": false,
        "dynamicSpread": true,
        "inline": false
      },
      "id": "_default_____404__svelte",
      "name": "[...404]",
      "file": {
        "path": "src/routes/root/[...404].svelte",
        "dir": "src/routes/root",
        "base": "[...404].svelte",
        "ext": ".svelte",
        "name": "[...404]"
      },
      "asyncModule": () => import('../src/routes/root/[...404].svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_beheer_svelte",
      "name": "beheer",
      "file": {
        "path": "src/routes/root/beheer.svelte",
        "dir": "src/routes/root",
        "base": "beheer.svelte",
        "ext": ".svelte",
        "name": "beheer"
      },
      "asyncModule": () => import('../src/routes/root/beheer.svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_groepen",
      "name": "groepen",
      "module": false,
      "file": {
        "path": "src/routes/root/groepen",
        "dir": "src/routes/root",
        "base": "groepen",
        "ext": "",
        "name": "groepen"
      },
      "children": [
        {
          "meta": {
            "dynamic": true,
            "order": false
          },
          "id": "_default_groepen___groupname__svelte",
          "name": "@[groupname]",
          "file": {
            "path": "src/routes/root/groepen/@[groupname].svelte",
            "dir": "src/routes/root/groepen",
            "base": "@[groupname].svelte",
            "ext": ".svelte",
            "name": "@[groupname]"
          },
          "asyncModule": () => import('../src/routes/root/groepen/@[groupname].svelte'),
          "children": []
        },
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_groepen_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/groepen/index.svelte",
            "dir": "src/routes/root/groepen",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/groepen/index.svelte'),
          "children": []
        }
      ]
    },
    {
      "meta": {
        "isDefault": true
      },
      "id": "_default_index_svelte",
      "name": "index",
      "file": {
        "path": "src/routes/root/index.svelte",
        "dir": "src/routes/root",
        "base": "index.svelte",
        "ext": ".svelte",
        "name": "index"
      },
      "asyncModule": () => import('../src/routes/root/index.svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_instellingen",
      "name": "instellingen",
      "module": false,
      "file": {
        "path": "src/routes/root/instellingen",
        "dir": "src/routes/root",
        "base": "instellingen",
        "ext": "",
        "name": "instellingen"
      },
      "children": [
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_instellingen_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/instellingen/index.svelte",
            "dir": "src/routes/root/instellingen",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/instellingen/index.svelte'),
          "children": []
        }
      ]
    },
    {
      "meta": {},
      "id": "_default_leden",
      "name": "leden",
      "module": false,
      "file": {
        "path": "src/routes/root/leden",
        "dir": "src/routes/root",
        "base": "leden",
        "ext": "",
        "name": "leden"
      },
      "children": [
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_leden_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/leden/index.svelte",
            "dir": "src/routes/root/leden",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/leden/index.svelte'),
          "children": []
        }
      ]
    },
    {
      "meta": {
        "reset": 1
      },
      "id": "_default_login",
      "name": "login",
      "file": {
        "path": "src/routes/root/login/_reset.svelte",
        "dir": "src/routes/root/login",
        "base": "_reset.svelte",
        "ext": ".svelte",
        "name": "_reset"
      },
      "asyncModule": () => import('../src/routes/root/login/_reset.svelte'),
      "children": [
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_login_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/login/index.svelte",
            "dir": "src/routes/root/login",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/login/index.svelte'),
          "children": []
        }
      ]
    },
    {
      "meta": {
        "reset": true
      },
      "id": "_default_logout",
      "name": "logout",
      "file": {
        "path": "src/routes/root/logout/_reset.svelte",
        "dir": "src/routes/root/logout",
        "base": "_reset.svelte",
        "ext": ".svelte",
        "name": "_reset"
      },
      "asyncModule": () => import('../src/routes/root/logout/_reset.svelte'),
      "children": []
    },
    {
      "meta": {
        "reset": true
      },
      "id": "_default_onboarding",
      "name": "onboarding",
      "file": {
        "path": "src/routes/root/onboarding/_reset.svelte",
        "dir": "src/routes/root/onboarding",
        "base": "_reset.svelte",
        "ext": ".svelte",
        "name": "_reset"
      },
      "asyncModule": () => import('../src/routes/root/onboarding/_reset.svelte'),
      "children": [
        {
          "meta": {
            "dynamic": true,
            "order": false
          },
          "id": "_default_onboarding__step__svelte",
          "name": "[step]",
          "file": {
            "path": "src/routes/root/onboarding/[step].svelte",
            "dir": "src/routes/root/onboarding",
            "base": "[step].svelte",
            "ext": ".svelte",
            "name": "[step]"
          },
          "asyncModule": () => import('../src/routes/root/onboarding/[step].svelte'),
          "children": []
        },
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_onboarding_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/onboarding/index.svelte",
            "dir": "src/routes/root/onboarding",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/onboarding/index.svelte'),
          "children": []
        }
      ]
    },
    {
      "meta": {},
      "id": "_default_updates",
      "name": "updates",
      "module": false,
      "file": {
        "path": "src/routes/root/updates",
        "dir": "src/routes/root",
        "base": "updates",
        "ext": "",
        "name": "updates"
      },
      "children": [
        {
          "meta": {
            "isDefault": true
          },
          "id": "_default_updates_index_svelte",
          "name": "index",
          "file": {
            "path": "src/routes/root/updates/index.svelte",
            "dir": "src/routes/root/updates",
            "base": "index.svelte",
            "ext": ".svelte",
            "name": "index"
          },
          "asyncModule": () => import('../src/routes/root/updates/index.svelte'),
          "children": []
        }
      ]
    }
  ]
}